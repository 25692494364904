/* This helps to display Lookups inside modals
Maybe it breaks some layout somewhere, we will see :)
*/
/* Breaks scrolling :(
.slds-modal__content {
    overflow: visible;
    overflow-y: inherit;
}
*/

html {
    background-color: white;
}

.lob-no-wrap {
    white-space: nowrap !important;
}